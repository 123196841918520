import React from 'react'
import { Button, Form, MosaicSection } from '~/components'
import { useMediaQuery } from 'react-responsive'
import { Image } from './ImageSection'
import cn from 'classnames'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Contact.module.styl'
import FrontDesk from '~/assets/img/Recepcao.jpg'

interface Option {
  id: number
  name: string
}

const Contact = () => {
  const { specialties }: { specialties: Array<Option> } = useApi(
    { specialties: [] },
    'page-provider'
  )
  const { subjects }: { subjects: Array<Option> } = useApi(
    { subjects: [] },
    'page-contact'
  )

  const desktop = useMediaQuery({
    query: '(min-width: 768px)',
  })

  return (
    <>
      {!desktop && (
        <MosaicSection
          image={FrontDesk}
          title="Confira os nossos canais de atendimento"
          description={
            <>
              Entre em contato direto com os setores {'>'} Comercial, Recursos
              Humanos, Compras e Suprimentos e Plantão de Vendas MoMA Residence.
              <br />
              <br />
              Rua Antônio Manoel Moreira, 175 - sala 05
              <br />
              Ed. Porto Januária - Fazenda
              <br />
              CEP 88301-640 - Itajaí/SC
              <br />
              <br />
              <strong>Comercial</strong>
              <br />
              <a
                href="https://api.whatsapp.com/send/?phone=5547992866003&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noreferrer"
              >
                <strong>(47) 99286-6003</strong>
              </a>
              <br />
              <br />
              <strong>Compras</strong>
              <br />
              <a href="tel:47991362198">
                <strong>(47) 99135-2198</strong>
              </a>
              <br />
              <br />
              <strong>Escritório</strong>
              <br />
              <a href="tel:4721225116">
                <strong>(47) 2122-5116</strong>
              </a>
              <br />
              <br />
              <strong>RH</strong>
              <br />
              <a href="tel:47999930371">
                <strong>(47) 99993-0371</strong>
              </a>
            </>
          }
        />
      )}
      <section className={st.core}>
        <div className={st.container}>
          {desktop && (
            <div className={st.head}>
              <Image image={FrontDesk} className={st.image} />
              <div className={st.text}>
                <h3>Confira os nossos canais de atendimento</h3>
                <p>
                  Entre em contato direto com os setores {'>'} Comercial,
                  Recursos Humanos, Compras e Suprimentos e Plantão de Vendas
                  MoMA Residence.
                  <br />
                  <br />
                  Rua Antônio Manoel Moreira, 175 - sala 05
                  <br />
                  Ed. Porto Januária - Fazenda
                  <br />
                  CEP 88301-640 - Itajaí/SC
                  <br />
                  <br />
                  <strong>Comercial</strong>
                  <br />
                  <a
                    href="https://api.whatsapp.com/send/?phone=5547992866003&text&type=phone_number&app_absent=0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <strong>(47) 99286-6003</strong>
                  </a>
                  <br />
                  <br />
                  <strong>Compras</strong>
                  <br />
                  <strong>(47) 99135-2198</strong>
                  <br />
                  <br />
                  <strong>Escritório</strong>
                  <br />
                  <strong>(47) 2122-5116</strong>
                  <br />
                  <br />
                  <strong>RH</strong>
                  <br />
                  <strong>(47) 99993-0371</strong>
                </p>
              </div>
            </div>
          )}
          <div className={cn(st.forms, st.tab)}>
            <div id="FaleConosco">
              <div>
                <h3>Fale conosco</h3>
                <p>
                  Utilize este canal para entrar em contato com nossa equipe de
                  atendimento. Envie sua mensagem, solicite informações e tire
                  suas dúvidas.
                </p>
                <Button
                  className={st.whatsapp}
                  href="https://api.whatsapp.com/send/?phone=5547992866003&text&type=phone_number&app_absent=0"
                  external
                >
                  Chamar no WhatsApp
                </Button>
              </div>
              <Form
                idPrefix="contact"
                rdStation="site-fale-conosco"
                button={<Button>Solicitar contato</Button>}
                loadingButton={<Button>Carregando...</Button>}
                hiddenInputs={{
                  legal_bases: [
                    {
                      category: 'communications',
                      type: 'consent',
                      status: 'granted',
                    },
                    {
                      category: 'data_processing',
                      type: 'consent',
                      status: 'granted',
                    },
                  ],
                }}
                inputs={[
                  {
                    label: 'Nome*',
                    name: 'name',
                  },
                  {
                    label: 'E-mail*',
                    name: 'email',
                    type: 'email',
                  },
                  {
                    label: 'WhatsApp*',
                    name: 'phone',
                    mask: 'phone',
                  },
                  {
                    label: 'Interesse do contato*',
                    name: 'subject',
                    placeholder: 'Selecione',
                    options: subjects.map(({ name }) => ({
                      name,
                    })),
                  },
                  {
                    label: 'Mensagem*',
                    name: 'message',
                    type: 'textarea',
                    className: st.wide,
                  },
                  {
                    label: (
                      <>Declaro que li e aceito a Política de Privacidade</>
                    ),
                    name: 'privacy',
                    type: 'checkbox',
                    className: st.privacy,
                  },
                ]}
              />
            </div>
          </div>
          <div className={cn(st.forms, st.tab)}>
            <div id="TrabalheConosco">
              <div>
                <h3>Trabalhe conosco</h3>
                <p>
                  Somos a soma de talentos composto por várias áreas, com o foco
                  em aprimorar investimentos no segmento imobiliário no sul do
                  Brasil. Faça parte da nossa equipe, e cadastre o seu currículo
                  para novas possibilidades.
                </p>
              </div>
              <Form
                idPrefix="curriculum"
                path="page-curriculum"
                button={<Button>Enviar currículo</Button>}
                loadingButton={<Button>Carregando...</Button>}
                inputs={[
                  {
                    label: 'Nome*',
                    name: 'name',
                  },
                  {
                    label: 'E-mail*',
                    name: 'email',
                    type: 'email',
                  },
                  {
                    label: 'WhatsApp*',
                    name: 'phone',
                    mask: 'phone',
                  },
                  {
                    label: 'Currículo*',
                    name: 'file',
                    type: 'file',
                    file: {
                      accept: 'application/msword, application/pdf',
                      button: (props) => (
                        <button type="button" className={st.search} {...props}>
                          Procurar
                        </button>
                      ),
                    },
                  },
                  {
                    label: 'Mensagem*',
                    name: 'message',
                    type: 'textarea',
                    className: st.wide,
                  },
                  {
                    label: (
                      <>Declaro que li e aceito a Política de Privacidade</>
                    ),
                    name: 'privacy',
                    type: 'checkbox',
                    className: st.privacy,
                  },
                ]}
              />
            </div>
          </div>
          <div id="ParceiroAikon" className={cn(st.forms, st.tab)}>
            <div>
              <div>
                <h3>Seja um parceiro da Aikon Empreendimentos</h3>
                <p>
                  Você possui algum produto ou serviço e gostaria de nos
                  apresentar? Preencha os campos ao lado com os seus produtos
                  e/ou suas atividades.
                </p>
              </div>
              <Form
                idPrefix="provider"
                path="page-provider"
                button={<Button>Enviar mensagem</Button>}
                loadingButton={<Button>Carregando...</Button>}
                inputs={[
                  {
                    label: 'Nome*',
                    name: 'name',
                  },
                  {
                    label: 'E-mail*',
                    name: 'email',
                    type: 'email',
                  },
                  {
                    label: 'WhatsApp*',
                    name: 'phone',
                    mask: 'phone',
                  },
                  {
                    label: 'Especialidade*',
                    name: 'specialty',
                    placeholder: 'Selecione',
                    options: specialties.map(({ name }) => ({
                      name,
                    })),
                  },
                  {
                    label: 'Mensagem*',
                    name: 'message',
                    type: 'textarea',
                    className: st.wide,
                  },
                  {
                    label: (
                      <>Declaro que li e aceito a Política de Privacidade</>
                    ),
                    name: 'privacy',
                    type: 'checkbox',
                    className: st.privacy,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
